import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { FaSpinner } from 'react-icons/fa'
import '../styles//modules/login.scss'

const Login = () => {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    loginWithRedirect()
  }, [loginWithRedirect])


  return (
    <div className="text-center text-2xl mt-10 mb-10">
      <FaSpinner className="mx-auto mb-4 spinner"/>
      <h2>Redirecting to Log In...</h2>
    </div>
  )
}

export default Login
